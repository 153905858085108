<template>
  <div class="container pt-3 pb-4">
    <hooper :settings="customSettings ? customSettings : hooperSettings">
      <slide v-if="productos.includes('sustenta-neutro') || productos.includes('nutricion-clinica-adultos')">
        <router-link :to="{ name: 'SustentaNeutro' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_SustentaNeutro.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('sustenta-vainilla') || productos.includes('nutricion-clinica-adultos')">
        <router-link :to="{ name: 'SustentaVainilla' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_SustentaVainilla.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('sustenta-db-neutro') || productos.includes('nutricion-clinica-adultos')">
        <router-link :to="{ name: 'SustentaDBNeutro' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_SustentaDBNeutro.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('sustenta-db-vainilla') || productos.includes('nutricion-clinica-adultos')">
        <router-link :to="{ name: 'SustentaDBVainilla' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_SustentaDBVainilla.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('sustenta-semielemental') || productos.includes('nutricion-clinica-adultos')">
        <router-link :to="{ name: 'SustentaSemielemental' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_SemielementalAdultos.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('sustenta-fibras') || productos.includes('nutricion-clinica-adultos')">
        <router-link :to="{ name: 'SustentaFibras' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_SustentaFibras.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('sustenta-dessert') || productos.includes('nutricion-clinica-adultos')">
        <router-link :to="{ name: 'SustentaDessert' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_Dessert.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('sustenta-junior-sin-lactosa') || productos.includes('nutricion-clinica-pediatricos')">
        <router-link :to="{ name: 'SustentaJuniorSinLactosa' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_JuniorSinLactosa.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('sustenta-junior-semielemental') || productos.includes('nutricion-clinica-pediatricos')">
        <router-link :to="{ name: 'SustentaJuniorSemielemental' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_JuniorSemielemental.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('lipidgen') || productos.includes('nutricion-clinica-pediatricos')">
        <router-link :to="{ name: 'Lipidgen' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_Lipidgen.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('espesante-resist') || productos.includes('modulos-nutricionales')">
        <router-link :to="{ name: 'EspesanteResist' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_EspesanteResist.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('espesante-resist-video')">
        <a href="https://youtu.be/l1X55HozQ-I" target="_blank">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/video.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </a>
      </slide>
      <slide v-if="productos.includes('medium-chain-lipids-oil') || productos.includes('modulos-nutricionales')">
        <router-link :to="{ name: 'MediumChainLipidsOil' , params: {flia: 'modulos-nutricionales'}}">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Botellas_Oil.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('medium-chain-lipids') || productos.includes('modulos-nutricionales')">
        <router-link :to="{ name: 'MediumChainLipids', params: {flia: 'modulos-nutricionales'} }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_MediumChainLipids.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('poli-carb') || productos.includes('modulos-nutricionales')">
        <router-link :to="{ name: 'PoliCarb'}">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_PoliCarb.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('dextrosa') || productos.includes('modulos-nutricionales')">
        <router-link :to="{ name: 'Dextrosa'}">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_Dextrosa.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('lactoproteyn') || productos.includes('modulos-nutricionales')">
        <router-link :to="{ name: 'Lactoproteyn' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_Lactoproteyn.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('fibras') || productos.includes('modulos-nutricionales')">
        <router-link :to="{ name: 'Fibras' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_Fibras.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('cetogenik') || productos.includes('dieta-cetogenica')">
        <router-link :to="{ name: 'Cetogenik' }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_Cetogenik.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('medium-chain-lipids-oil') || productos.includes('dieta-cetogenica')">
        <router-link :to="{ name: 'MediumChainLipidsOil' , params: {flia: 'dieta-cetogenica'}}">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Botellas_Oil.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('medium-chain-lipids') || productos.includes('dieta-cetogenica')">
        <router-link :to="{ name: 'MediumChainLipids', params: {flia: 'dieta-cetogenica'} }">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/Lata_MediumChainLipids.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('hang-pack-junior-sin-lactosa') || productos.includes('hang-pack')">
        <router-link :to="{ name: 'HangPackJuniorSinLactosa'}">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/HangPack_JuniorSinLactosa.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('hang-pack-semielemental') || productos.includes('hang-pack')">
        <router-link :to="{ name: 'HangPackJuniorSemielemental'}">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/HangPack_JuniorSemielemental.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('hang-pack-sustenta-neutro') || productos.includes('hang-pack')">
        <router-link :to="{ name: 'HangPackSustentaNeutro'}">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/HangPack_SustentaNeutro.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('hang-pack-sustenta-semielemental') || productos.includes('hang-pack')">
        <router-link :to="{ name: 'HangPackSustentaSemielemental'}">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/HangPack_SustentaSemielemental.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <slide v-if="productos.includes('hang-pack-sustenta-db-neutro') || productos.includes('hang-pack')">
        <router-link :to="{name: 'HangPackSustentaDBNeutro'}">
          <div class="card h-100">
            <div class="card-image">
              <img
                src="@/assets/images/productos/cards/HangPack_SustentaDBNeutro.webp"
                class="card-img-top"
                alt=""
              />
            </div>
          </div>
        </router-link>
      </slide>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "CardsRecomendados",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  props: {
    productos: Array,
    customSettings: Object,
  },
  data() {
    return {
      hooperSettings: {
        // itemsToShow: 5,
        infiniteScroll: true,
        trimWhiteSpace: true,
        breakpoints: {
          320: {
            centerMode: true,
            itemsToShow: 2,
            pagination: "fraction",
          },
          600: {
            centerMode: true,
            itemsToShow: 4,
            pagination: "fraction",
          },
          900: {
            centerMode: true,
            itemsToShow: 5,
            pagination: "fraction",
          },
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
.card {
  border: none;
  cursor: pointer;
  width: 170px;
}

</style>
